<template>
	
	<div class="cont" id="p11">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item">
						<div class="title">가입일</div>
						<div class="content">
							<div class="cont-date">
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="search.inquiryStartDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 120px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="search.inquiryEndDate = ''; datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 350px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div class="c-item">
						<div class="title">상태</div>
						<div class="content">
							<select
								v-model="search.status"
								@change="getData"
							>
								<option
									v-for="(item, index) in status"
									:key="index"
									:value="item.code"
								>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="상호명, 대표자명, ID를 입력하세요."
								v-model="search.keyword"
							>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<a ><div class="btn-excel"><vue-excel-xlsx
							:data="excel_items"
							:columns="columns"
							:filename="program.name"
							:sheetname="program.name"
						>
						엑셀 다운로드
						</vue-excel-xlsx></div></a>
				</div>
			</div>
			<div class="right">
				<div class="group g04">
					<router-link :to="{ name: 'MerchantsApplyList'}">
						<div class="title">
							<div class="gt-l">
								<span class="p1">가맹점 심사 현황</span>
							</div>
							<div class="gt-r">
								<i class="xi-angle-right-min"></i>
							</div>
						</div>
					</router-link>
					<a @click="overlayView"
						v-if="false">
						<div class="title">
							<div class="gt-l">
								<span class="p1">가맹점 심사 현황</span>
							</div>
							<div class="gt-r">
								<i class="xi-angle-right-min"></i>
							</div>
						</div>
					</a>
					<div class="content02">
						<div class="citem">
							<div class="cp">심사 대기</div>
							<div class="camount"><span class="em">{{ summary.reviewCount | makeComma}}</span>건</div>
						</div>
						<div class="citem">
							<div class="cp">반려</div>
							<div class="camount"><span class="em">{{ summary.rejectCount | makeComma }}</span>건</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="c02">
			<div class="table type01">
				<div class="item-header">
					<div class="item">
						<div class="td">NO</div>
						<div class="td">사업자</div>
						<div class="td">상호명(법인명)</div>
						<div class="td">대표자</div>
						<div class="td">정산주기</div>
						<div class="td">연한도</div>
						<div class="td">총 결제금액</div>
						<div class="td">잔여 한도</div>
						<div class="td">가입일</div>
						<div class="td">상태</div>
						<div class="td">상세</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in items"
						:key="index"
					>
						<div class="td">{{ item.rowNumber }}</div>
						<div class="td">{{ item.companyTypeName }}</div>
						<div class="td">{{ item.businessName }}</div>
						<div class="td">{{ item.ceoName }}</div>
						<div class="td">{{ item.settlementCycleName.replace('일정산', 'D') }}</div>
						<div class="td text-right">{{ item.yearLimit | makeComma}}원</div>
						<div class="td text-right">{{ item.totalPaymentAmount | makeComma }}원</div>
						<div class="td text-right">{{ item.yearLimitRemainingLimit | makeComma  }}원</div>
						<div class="td">{{ item.joinDate | transDate }}</div>
						<div class="td">  <!-- display block = class "active" -->
							<div class="active"
								:class="item.statusName == '정상사용' ? 'tp01' : (item.statusName == '사용중지' ? 'tp02' : 'tp03')"
							><a >{{ item.statusName }} <i class="xi-play" v-show="false"></i></a></div>
						</div>
						<div class="td"><router-link :to="{ name: 'FranchiseeDetail', params: {pgMerchNo: item.pgMerchNo }}" >상세보기</router-link></div>
					</div>
				</div>
								
				<div
					v-show="!items.length"
					class="no-data"
				>
					No Data
				</div>

				
				<Pagination 
					:align="'center'"
					:options="search"
				/>
			</div>
		</div>
		
		<ConfirmList 
			:overlay="overlay"
			@click="close"
			v-if="false"
		/>
		
	</div>
</template>

<script>

	import ConfirmList from '@/view/Franchisee/ConfirmList'
	import Pagination from '@/components/Pagination'
	
	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	import { columns } from '@/resources/columns/columns'
	
	export default {
		name: 'AgentList'
		,props: ['user', 'codes']
		,components: { ConfirmList, Pagination }
		,data: function(){
			return {
				program: {
					name: '가맹점 목록'
				}
				,summary: {
					rejectCount: 0
					,reviewCount: 0
				}
				,items: []
				,excel_items: []
				,columns: columns.Merchant.list
				,search: {
					inquiryEndDate: ''
					,inquiryStartDate: '20200101'
					,keyword: ''
					,status: ''
					,limitApplyStatus: ''
					,page: 1
					,list_cnt: 10
					,page_cnt: 10
					,total_count: 1
					,link:'/Franchisee/'
				}
				,status: this.codes.merchants.status
				,overlay: false
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
			}
		}
		,computed: {
		}
		,filter: filter
		,methods: {
			overlayView: function(){
				this.$emit('setOverlay')
				this.overlay = true
			}
			,close: function(){
				this.$emit('setOverlay')
				this.overlay = false
			}
			,getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						await this.getApplySummary()
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getApplySummary: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: 'merchants/apply/summary'
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,clear: function(){
				this.search.keyword = ''
				this.search.inquiryEndDate = ''
				this.search.inquiryStartDate = '20200101'
				this.search.status = ''
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/download'
						,data: {
							inquiryStartDate: this.search.inquiryStartDate
						}
						,authorize: true
					})
					
					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: function(){
			this.getData()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.$router.push('/Franchisee/' + this.search.page)
					this.getData()
				}
			}
			,'search.inquiryStartDate': {
				handler: function(call){
					this.search.inquiryStartDate = call.replaceAll('-', '')
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
					this.search.inquiryEndDate = call.replaceAll('-', '')
				}
			}
		}
	}
</script>





